import React from 'react';
import AmmTile from 'common/components/AmmTile';
import { useQuery } from '@apollo/client';
import { QueryItemsResponse, QueryName } from 'common/models/graphql';
import { listDexesQuery } from 'graphql/queries/listDexes.query';
import { Dex } from 'common/models/dex';
import { REFRESH_INTERVALS } from 'common/constants/constants';
import Container from 'common/components/UI/Container';
import _ from 'lodash';


const AmmsContainer: React.FC = () => {
  const {
    loading: loadingDexes,
    error,
    data: dataDexes
  } = useQuery<QueryItemsResponse<Dex>>(listDexesQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listDexes]
  });

  // First filter active DEXes
  const activeDexes: Dex[] = dataDexes?.listDexes?.items 
    ? dataDexes.listDexes.items.filter(d => d.isActive).sort((a, b) => a.chainId-b.chainId)
    : [];

  // Group DEXes by dexName
  const groupedDexes = _.groupBy(activeDexes, 'dexName');

  // Transform grouped data into aggregated format
  const aggregatedDexes = Object.entries(groupedDexes).map(([dexName, dexGroup]) => ({
    id: dexGroup[0].id,
    dexName,
    dexUrl: dexGroup[0].dexUrl,
    isActive: true,
    tvl: _.sumBy(dexGroup, 'tvl'),
    numVaults: _.sumBy(dexGroup, 'numVaults'),
    chains: dexGroup.map(d => d.chainId)
  }));

  // Sort by TVL in descending order
  const sortedDexes = _.orderBy(aggregatedDexes, ['tvl'], ['desc']);

  return (
    <Container>
      <h1 className='pt-24 pb-20'><span className='text-accent'>Boost your earnings</span><br /> across supported platforms</h1>
      <div className='flex flex-row flex-wrap justify-between'>
        {sortedDexes.map((dex) => (
          <AmmTile title={dex.dexName} url={dex.dexUrl} dex={dex} />
        ))}
      </div>
    </Container>
  );
};

export default AmmsContainer;

import App from 'App';
import AmmsContainer from 'containers/AmmsContainer';
import React from 'react';


const Index: React.FC = () => {
  return (
    <App noGutter={true}>
      <AmmsContainer />
    </App>
  );
};
export default Index;

import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import FormatNumber from 'utils/FormatNumber';
import { chainNames } from 'common/constants/constants';

type AggregatedDex = {
  id: string;
  dexName: string;
  dexUrl: string;
  isActive: boolean;
  tvl: number;
  numVaults: number;
  chains: number[];
};

type Props = {
  title: string;
  description?: string;
  width?: string;
  titleFontSize?: string;
  url?: string;
  urlIcon?: boolean; // does it show the url icon next to the title
  imageSrc?: string;
  imageHeight?: string;
  imageMargin?: string;
  imageClassName?: string;
  dex: AggregatedDex;
}

const AmmTile: React.FC<Props> = (props) => {
  const dex = props.dex; 

  const chainList = dex.chains?.length > 2
  ? `${chainNames[dex.chains[0]]}, ${chainNames[dex.chains[1]]} & ${dex.chains.length - 2} more`
  : dex.chains.map(chainId => chainNames[chainId]).join(', ');

  return (
    <div className={`amm-tile flex flex-col rounded-[16px] p-5 mb-5 border gap-5 w-full sm:w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] lg:w-[calc(33.333%-1rem)] `}>
        <div className="flex flex-row items-center gap-3">
            <Image 
                src={`../images/amms/${dex.dexName.toLowerCase()}.svg?3`}
                className='amm-logo' 
                alt={props.title} 
                marginRight={props.imageMargin}
                height='60px' 
            />
            <div className="flex flex-col">
                <span className='font-medium text-lg'>{dex.dexName}</span>
                <div className="text-secondary font-medium">{chainList}</div>
            </div>
        </div>
        <div className="flex flex-row justify-start gap-12">
            <div className="flex flex-col">
                <span className='text-secondary font-medium text-xs'>TVL</span>
                <div className="font-medium text-lg">
                    <FormatNumber value={dex.tvl} prefix='$' abbreviate />
                </div>
            </div>
            <div className="flex flex-col">
                <span className='text-secondary font-medium text-xs'>VAULTS</span>
                <div className="font-medium text-lg flex flex-row gap-[6px] items-center">
                    <img src='../images/ichi.svg' className='h-4 mb-0 pb-0' />
                    {dex.numVaults}
                </div>
            </div>
        </div>
        <OutboundLink target="_blank" href={props.url} className="btn-link">
          <Button
            className="header-button w-full mb-3"
            title="Start Earning"
          />
        </OutboundLink>
    </div>
  )
};

export default AmmTile;

